<template>
    <zj-scroll-list identity="zRotaPersonnel" :title-list="titleList" :data-list="sentrieList" :delay="2000" :limit="5" @mobile="onMobile"></zj-scroll-list>
</template>

<script>
import ajax from '@/ajax.js'
import ZjScrollList from 'zj-scroll-list'
import XDate from '@/utils/x-date.js'
export default {
    name: 'ComRotaPersonnel',
    components: {
        ZjScrollList,
    },
    data(){
        return {
            titleList: [
                {flex: 3, title: '日期'},
                {flex: 4, title: '部门'},
                {flex: 5, title: '姓名'},
                {flex: 6, title: '电话'},
            ],
            sentries: [],
        }
    },
    computed:{
        sentrieList(){
            let list  = [];
            for(const it of this.sentries){
                list.push({
                    key: "key1",
                    row: [
                        { "flex": 3, /*"icon": require("@/static/icons/icon-date.svg"),*/ "text": XDate.formatTimestamp('m-d', XDate.toTimeStamp(it.date)), "tip": it.date, },
                        { "flex": 4, "text": it.group_name, "tip": it.group_name, },
                        { "flex": 5, "text": it.captain_name, "tip": it.captain_name, },
                        {
                            "flex": 6,
                            "icon": require("@/static/icons/icon-phone.svg"),
                            "icon_color": "none",
                            "text": it.phone,
                            "text_color": "none",
                            "tip": "拨打电话 "+ it.phone,
                            "emit": "mobile",
                        },
                    ],
                    phone: it.phone,
                    surname: it.surname,
                });
            
            }
            return list;
        },
    },
    created(){
        this.download();
    },
    methods:{
        download(){
            ajax({
                url: '/api/show_duty_screen',
                method: "POST",
                param: {date: XDate.formatTimestamp('Y-m')} //'2021-01'
            })
            .then( res =>{
                // console.log("download:", res )
                this.sentries = res;
            });
        },
        update(){
        },
        onMobile(e){
            // console.log("onMobile: ",e );
            this.$store.dispatch('showWindow', 'comDialUp')
            const phone = e.phone;
            this.$store.dispatch('setWindowConfig', {window: 'comDialUp', config: {phone: phone}})
        },
    },
    watch:{
        sentries(val, old){
            // console.log("watch: sentries:", val)
            this.update();
        }
    },
}
</script>